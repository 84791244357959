<div class="bgimg">
<div *ngIf="!isMobileResolution">
    <div style="text-align: left;">
      <div style="padding: 5px;text-align: left;"><h2><strong>Informationen zur Rückmeldung einer Markierung bzw. eines markierten Fisches</strong></h2></div>
      <table cellpadding="5" cellspacing="0" style="width: 100%" border="3">
        <tbody>
        <tr>
          <td><br>
      <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
        <thead>
        <th></th><th><strong>Fischart</strong></th><th></th><th><strong>Datum des Fundes</strong></th><th></th><th><strong>Gewässer</strong></th><th></th>
        </thead>
        <tbody>
        <tr>
          <td style="width: 14.285%" ></td>
          <td class="bordered" style="width: 14.285%" >
            <mat-form-field>
              <input matInput placeholder="Wählen Sie eine Fischart" aria-label="Fisch" [(ngModel)]="fischtyp" (ngModelChange)="changeFisch()" [formControl]='fischeControl' [matAutocomplete]="auto2" >
              <mat-autocomplete #auto2="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
                <ng-container *ngIf="!isLoading">
                  <mat-option *ngFor="let fisch of filteredFische" [value]="fisch">
                    <span>{{ fisch.name }}</span>
                  </mat-option>
                </ng-container>
              </mat-autocomplete>
            </mat-form-field>
          </td>
          <td style="width: 14.285%" ></td>
          <td class="bordered" style="width: 14.285%"><input type="date" [(ngModel)]="gefunden.datum"></td>
          <td style="width: 14.285%" ></td>
          <td class="bordered" style="width: 14.285%">
          <mat-form-field>
            <input matInput placeholder="Wählen Sie ein Gewässer" aria-label="Fluss" [(ngModel)]="fluss" (ngModelChange)="changeFluss()" [formControl]='fluesseControl' [matAutocomplete]="auto" >
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
              <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
              <ng-container *ngIf="!isLoading">
                <mat-option *ngFor="let fluss of filteredFluesse" [value]="fluss">
                  <span>{{ fluss.name }}</span>
                </mat-option>
              </ng-container>
            </mat-autocomplete>
          </mat-form-field>
        </td>
          <td style="width: 14.285%" ></td>

        </tr>
        </tbody>
      </table><br>
    <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
<thead>
<th></th><th><strong>Sendertyp</strong></th><th></th><th><strong>Länge (cm)</strong></th><th></th><th><strong>Gewicht (g)</strong></th><th></th>
</thead>
<tbody>
<tr>
  <td style="width: 14.285%" ></td>
  <td class="bordered" style="width: 14.285%">
  <div *ngIf="sender.id==0">
    <mat-form-field>
      <mat-select placeholder="Wählen Sie den Sendertyp" [(ngModel)]="gefunden.sid" name="send">
        <mat-option *ngFor="let send of senders" [value]="send.id">
          {{send.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div *ngIf="sender.id!=0">
    {{sender.name}}
  </div></td>
  <td style="width: 14.285%" ></td>
  <td class="bordered" style="width: 14.285%"><input id="gefunden.gewict2" type="number" [(ngModel)]="gefunden.gewicht" /></td>
  <td style="width: 14.285%" ></td>
<td class="bordered" style="width: 14.285%"><input id="gefunden.laenge2" type="number" [(ngModel)]="gefunden.laenge" /></td>

  <td style="width: 14.285%" ></td>
</tr>
</tbody>
</table><br>
      <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
        <thead>
        <th></th><th><strong>Bemerkungen</strong></th><th></th>
        </thead>
        <tbody>
        <tr>
          <td style="width: 14.285%" ></td>

          <td style="width: 71.43%" class="bordered"><textarea id="gefunden.notiz2" cols="160" rows="2"  [(ngModel)]="gefunden.notiz"></textarea></td>
          <td style="width: 14.285%" ></td>

        </tr>
        </tbody>
      </table>
          <br></td></tr></tbody></table><br>
<div *ngIf="gefunden.fid!=0">
<table cellpadding="5" cellspacing="0" style="width: 100%" borderthead>
<thead><th></th><th><strong>Code auf dem Sender</strong></th><th></th>
</thead>
<tbody>
<tr>
  <td style="width: 42.855%" ></td>
  <td class="bordered" style="width: 14.285%">
  <mat-form-field>
    <input matInput placeholder="Wählen Sie den Code" aria-label="Code" [(ngModel)]="fisch" (ngModelChange)="changeCode()" [formControl]='senderControl' [matAutocomplete]="auto3" >
    <mat-autocomplete #auto3="matAutocomplete" [displayWith]="displayFn">
      <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
      <ng-container *ngIf="!isLoading">
        <mat-option *ngFor="let fisch of filteredCode" [value]="fisch">
          <span>{{ fisch.name }}</span>
        </mat-option>
      </ng-container>
    </mat-autocomplete>
  </mat-form-field>
</td>
  <td style="width: 42.855%" ></td>

</tr>
</tbody>
</table></div><br>
    </div></div>

<div *ngIf="isMobileResolution">
  <div style="text-align: left;">
    <div style="padding: 5px;text-align: left;"><h2><strong>Informationen zur Rückmeldung einer Markierung bzw. eines markierten Fisches</strong></h2></div>
    <table cellpadding="5" cellspacing="0" style="width: 100%" border="3">
      <tbody>
      <tr>
        <td><br>
          <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
            <thead>
            <th></th><th><strong>Fischart</strong></th><th></th>
            </thead>
            <tbody>
            <tr>
              <td style="width: 10%;padding-right: 0.5em"></td>
              <td class="bordered" style="width: 80%" >
                <mat-form-field>
                  <input matInput placeholder="Wählen Sie eine Fischart" aria-label="Fisch" [(ngModel)]="fischtyp" (ngModelChange)="changeFisch()" [formControl]='fischeControl' [matAutocomplete]="auto5" >
                  <mat-autocomplete #auto5="matAutocomplete" [displayWith]="displayFn">
                    <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
                    <ng-container *ngIf="!isLoading">
                      <mat-option *ngFor="let fisch of filteredFische" [value]="fisch">
                        <span>{{ fisch.name }}</span>
                      </mat-option>
                    </ng-container>
                  </mat-autocomplete>
                </mat-form-field>
              </td>
              <td style="width: 10%;padding-left: 0.5em"></td>
            </tr>
            </tbody>
          </table><br>
          <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
            <thead>
            <th></th><th><strong>Datum</strong></th><th></th>
            </thead>
            <tbody>
            <tr>
              <td style="width: 10%;padding-right: 0.5em"></td>
              <td class="bordered" style="width: 80%" >
                <input type="date" [(ngModel)]="gefunden.datum"></td>
              <td style="width: 10%;padding-left: 0.5em"></td>
            </tr>
            </tbody>
          </table><br>
          <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
            <thead>
            <th></th><th><strong>Gewässer</strong></th><th></th>
            </thead>
            <tbody>
            <tr>
              <td style="width: 10%;padding-right: 0.5em"></td>
              <td class="bordered" style="width: 80%" >
                <mat-form-field>
                  <input matInput placeholder="Wählen Sie ein Gewässer" aria-label="Fluss" [(ngModel)]="fluss" (ngModelChange)="changeFluss()" [formControl]='fluesseControl' [matAutocomplete]="auto6" >
                  <mat-autocomplete #auto6="matAutocomplete" [displayWith]="displayFn">
                    <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
                    <ng-container *ngIf="!isLoading">
                      <mat-option *ngFor="let fluss of filteredFluesse" [value]="fluss">
                        <span>{{ fluss.name }}</span>
                      </mat-option>
                    </ng-container>
                  </mat-autocomplete>
                </mat-form-field>
              </td>
              <td style="width: 10%;padding-left: 0.5em"></td>
            </tr>
            </tbody>
          </table><br>
          <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
            <thead>
            <th></th><th><strong>Sendertyp</strong></th><th></th>
            </thead>
            <tbody>
            <tr>
              <td style="width: 10%;padding-right: 0.5em"></td>
              <td class="bordered" style="width: 80%" >
                <div *ngIf="sender.id==0">
                  <mat-form-field>
                    <mat-select placeholder="Wählen Sie den Sendertyp" [(ngModel)]="gefunden.sid" name="send">
                      <mat-option *ngFor="let send of senders" [value]="send.id">
                        {{send.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div *ngIf="sender.id!=0">
                  {{sender.name}}
                </div></td>
              <td style="width: 10%;padding-left: 0.5em"></td>
            </tr>
            </tbody>
          </table><br>
          <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
            <thead>
            <th></th><th><strong>Länge (m)</strong></th><th></th>
            </thead>
            <tbody>
            <tr>

              <td style="width: 10%;padding-right: 0.5em"></td>
              <td class="bordered" style="width: 80%" >
                <input id="gefunden.gewict" type="number" [(ngModel)]="gefunden.gewicht" /></td>
              <td style="width: 10%;padding-left: 0.5em"></td>
            </tr>
            </tbody>
          </table><br>
          <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
            <thead>
            <th></th><th><strong>Gewicht (cm)</strong></th><th></th>
            </thead>
            <tbody>
            <tr>
              <td style="width: 10%;padding-right: 0.5em"></td>
              <td class="bordered" style="width: 80%" >
                <input id="gefunden.laenge" type="number" [(ngModel)]="gefunden.laenge" /></td>
              <td style="width: 10%;padding-left: 0.5em"></td>
            </tr>
            </tbody>
          </table><br>
          <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
            <thead>
            <th></th><th><strong>Bemerkungen</strong></th><th></th>
            </thead>
            <tbody>
            <tr>
              <td style="width: 10%" ></td>

              <td style="width: 80%" class="bordered"><textarea id="gefunden.notiz" cols="30" rows="2"  [(ngModel)]="gefunden.notiz"></textarea></td>
              <td style="width: 10%" ></td>

            </tr>
            </tbody>
          </table>
          <br></td></tr></tbody></table><br>
    <div *ngIf="gefunden.fid!=0">
      <table cellpadding="5" cellspacing="0" style="width: 100%" borderthead>
        <thead><th></th><th><strong>Code auf dem Sender</strong></th><th></th>
        </thead>
        <tbody>
        <tr>
          <td style="width: 10%" ></td>
          <td class="bordered" style="width: 80%">
            <mat-form-field>
              <input matInput placeholder="Wählen Sie den Code" aria-label="Code" [(ngModel)]="fisch" (ngModelChange)="changeCode()" [formControl]='senderControl' [matAutocomplete]="auto3" >
              <mat-autocomplete #auto3="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
                <ng-container *ngIf="!isLoading">
                  <mat-option *ngFor="let fisch of filteredCode" [value]="fisch">
                    <span>{{ fisch.name }}</span>
                  </mat-option>
                </ng-container>
              </mat-autocomplete>
            </mat-form-field>
          </td>
          <td style="width: 10%" ></td>

        </tr>
        </tbody>
      </table></div><br>
  </div></div>

      <div style="padding: 5px;text-align: left;"><h2><strong>Informationen zum Fang- resp. Nachweisort</strong></h2></div>
      <table cellpadding="5" cellspacing="0" style="width: 100%" border="3">
        <tbody>
        <tr>
          <td>
      <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
        <tbody>
        <tr>
          <td class="map-tab">
<br>
      <div class="map-frame">
        <div id="map"></div>
      </div>

    <br><br><br><br><br><br><br><br><br><br> <br><br><br><br><br><br><br> <br><br><br><br><br><br><br> <br><br><br><br><br><br><br>
          </td><td class="cord-tab">
          <!--<div *ngIf="!isMobileResolution">
          <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
    <thead>
    <th></th><th><strong>Breitengrad / Latitude</strong></th>
    </thead>
    <tbody>
    <tr><td></td><td style="width: 35%" class="bordered">{{gefunden.ycord}}</td><td style="width: 35%"></td>
    </tr>
    </tbody>
  </table><br>
          <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
            <thead>
            <th></th><th><strong>Längengrad / Longitude</strong></th>
            </thead>
            <tbody>
            <tr><td></td><td style="width: 35%" class="bordered">{{gefunden.xcord}}</td><td style="width: 35%">

            </tr>
            </tbody>
          </table>
          </div>-->
          </td>
        </tr>
        <tr><td>
         <!-- <div *ngIf="isMobileResolution">
          <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
          <thead>
          <th></th><th><strong>Breitengrad / Latitude</strong></th><th></th>
          </thead>
          <tbody>
          <tr>
            <td style="width: 10%" ></td>
            <td style="width: 80%" class="bordered">{{gefunden.ycord}}</td>
            <td style="width: 10%" ></td>
          </tr>
          </tbody>
        </table><br>
          <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
            <thead>
            <th></th><th><strong>Längengrad / Longitude</strong></th><th></th>
            </thead>
            <tbody>
            <tr>
              <td style="width: 10%" ></td>
              <td style="width: 80%" class="bordered">{{gefunden.xcord}}</td>
              <td style="width: 10%" ></td>
            </tr>
            </tbody>
          </table>
          </div>-->
        </td></tr>
        </tbody>
      </table>
          </td>
        </tr>
        </tbody>
      </table>
         <br><br>

  <div *ngIf="!isMobileResolution">
    <div style="text-align: left;">
    <div style="padding: 5px;text-align: left;"><h2><strong>Informationen zur Kontaktaufnahme</strong></h2></div>
      <table cellpadding="5" cellspacing="0" style="width: 100%" border="3">
        <tbody>
        <tr>
          <td><br>
            <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
              <thead>
              <th></th><th><strong>Name</strong></th><th></th><th><strong>Vorname</strong></th><th></th><th><strong>E-Mail</strong></th><th></th>
              </thead>
              <tbody>
              <tr>
                <td style="width: 14.285%" ></td>
                <td class="bordered" style="width: 14.285%" ><input id="gefunden.name2" type="text" [(ngModel)]="finder.name" /></td>
                <td style="width: 14.285%" ></td>
                <td class="bordered" style="width: 14.285%" ><input id="gefunden.vorname2" type="text" [(ngModel)]="finder.vorname" /></td>
                <td style="width: 14.285%" ></td>
                <td class="bordered" style="width: 14.285%" ><input id="gefunden.email2" type="text" [(ngModel)]="finder.email" /></td>
                <td style="width: 14.285%" ></td>
              </tr>
              </tbody>
            </table><br>
            <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
              <thead>
              <th></th><th><strong>PLZ</strong></th><th></th><th><strong>Ort</strong></th><th></th><th><strong>Straße u. Nummer</strong></th><th></th>
              </thead>
              <tbody>
              <tr>
                <td style="width: 14.285%" ></td>
                <td class="bordered" style="width: 14.285%" ><input id="gefunden.plz2" type="text" [(ngModel)]="finder.plz" /></td>
                <td style="width: 14.285%" ></td>
                <td class="bordered" style="width: 14.285%" ><input id="gefunden.ort2" type="text" [(ngModel)]="finder.ort" /></td>
                <td style="width: 14.285%" ></td>
                <td class="bordered" style="width: 14.285%" ><input id="gefunden.strasse2" type="text" [(ngModel)]="finder.strasse" /></td>
                <td style="width: 14.285%" ></td>
              </tr>
              </tbody>
            </table><br>
          </td>
        </tr>
        </tbody>
      </table>

      <br>
      <div style="padding: 5px;text-align: left;"><h2><strong>Bankverbindung für den Finderlohn</strong></h2></div>
      <table cellpadding="5" cellspacing="0" style="width: 100%" border="3">
        <tbody>
        <tr>
          <td><br>
            <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
              <thead>
              <th></th><th><strong>Bank</strong></th><th></th><th><strong>IBAN</strong></th><th></th><th><strong>BIC</strong></th><th></th>
              </thead>
              <tbody>
              <tr>
                <td style="width: 14.285%" ></td>
                <td class="bordered" style="width: 14.285%" ><input id="gefunden.telefon2" type="text" /></td>
                <td style="width: 14.285%" ></td>
                <td class="bordered" style="width: 14.285%" ><input id="gefunden.iban2" type="text" [(ngModel)]="finder.iban" /></td>
                <td style="width: 14.285%" ></td>
                <td class="bordered" style="width: 14.285%" ><input id="gefunden.bic2" type="text" /></td>
                <td style="width: 14.285%" ></td>
              </tr>
              </tbody>
            </table><br>
          </td>
        </tr>
        </tbody>
      </table>
   <br>
   <table cellpadding="5" cellspacing="0" style="width: 100%" border="0"><tr>
           <td width="50%" style="text-align: right;"><div *ngIf="gefunden.fid!=0">
              <button mat-raised-button color="primary" size="large" (click)="save()"><div style="font-size: 2em">Daten übertragen</div></button>
    </div></td><td width="50%" style="text-align: left;">
           <div *ngIf="pdf!=0">
              &nbsp;<button mat-raised-button color="accent" size="large"><a href="./api/pdf.php?pid={{pdf}}" style="text-decoration:none" target="blank">
           <div style="font-size: 2em;color: white">PDF erstellen</div></a></button>
           </div></td></tr></table></div></div><br>

<div *ngIf="isMobileResolution">
  <div style="text-align: left;">
    <div style="padding: 5px;text-align: left;"><h2><strong>Informationen zur Kontaktaufnahme</strong></h2></div>
    <table cellpadding="5" cellspacing="0" style="width: 100%" border="3">
      <tbody>
      <tr>
        <td><br>
          <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
            <thead>
            <th></th><th><strong>Name</strong></th><th></th>
            </thead>
            <tbody>
            <tr>
              <td style="width: 10%" ></td>
              <td style="width: 80%" class="bordered"><input id="gefunden.name" type="text" [(ngModel)]="finder.name" /></td>
              <td style="width: 10%" ></td>
            </tr>
            </tbody>
          </table><br>
          <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
            <thead>
            <th></th><th><strong>Vorname</strong></th><th></th>
            </thead>
            <tbody>
           <tr>
              <td style="width: 10%" ></td>
              <td style="width: 80%" class="bordered"><input id="gefunden.vorname" type="text" [(ngModel)]="finder.vorname" /></td>
              <td style="width: 10%" ></td>
            </tr>
            </tbody>
          </table><br>
          <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
            <thead>
            <th></th><th><strong>E-Mail</strong></th><th></th>
            </thead>
            <tbody>
            <tr>
              <td style="width: 10%" ></td>
              <td style="width: 80%" class="bordered"><input id="gefunden.email" type="text" [(ngModel)]="finder.email" /></td>
              <td style="width: 10%" ></td>
            </tr>
            </tbody>
          </table><br>
          <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
            <thead>
            <th></th><th><strong>PLZ</strong></th><th></th>
            </thead>
            <tbody>
            <tr>
              <td style="width: 10%" ></td>
              <td style="width: 80%" class="bordered"><input id="gefunden.plz" type="text" [(ngModel)]="finder.plz" /></td>
              <td style="width: 10%" ></td>
            </tr>
            </tbody>
          </table><br>
          <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
            <thead>
            <th></th><th><strong>Ort</strong></th><th></th>
            </thead>
            <tbody>
            <tr>
              <td style="width: 10%" ></td>
              <td style="width: 80%" class="bordered"><input id="gefunden.ort" type="text" [(ngModel)]="finder.ort" /></td>
              <td style="width: 10%" ></td>
            </tr>
            </tbody>
          </table><br>
          <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
            <thead>
            <th></th><th><strong>Straße u. Nummer</strong></th><th></th>
            </thead>
            <tbody>
            <tr>
              <td style="width: 10%" ></td>
              <td style="width: 80%" class="bordered"><input id="gefunden.strasse" type="text" [(ngModel)]="finder.strasse" /></td>
              <td style="width: 10%" ></td>
            </tr>
            </tbody>
          </table><br>
        </td>
      </tr>
      </tbody>
    </table>
    <br>
    <div style="padding: 5px;text-align: left;"><h2><strong>Bankverbindung für den Finderlohn</strong></h2></div>
    <table cellpadding="5" cellspacing="0" style="width: 100%" border="3">
      <tbody>
      <tr>
        <td><br>
          <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
            <thead>
            <th></th><th><strong>Bank</strong></th><th></th>
            </thead>
            <tbody>
            <tr>
              <td style="width: 10%" ></td>
              <td style="width: 80%" class="bordered"><input id="gefunden.telefon" type="text" /></td>
              <td style="width: 10%" ></td>
            </tr>
            </tbody>
          </table><br>
                <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
                  <thead>
                  <th></th><th><strong>IBAN</strong></th><th></th>
                  </thead>
                  <tbody>
                  <tr>
                    <td style="width: 10%" ></td>
                    <td style="width: 80%" class="bordered"><input id="gefunden.iban" type="text" [(ngModel)]="finder.iban" /></td>
                    <td style="width: 10%" ></td>
                  </tr>
                  </tbody>
                </table><br>
                      <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
                        <thead>
                        <th></th><th><strong>BIC</strong></th><th></th>
                        </thead>
                        <tbody>
                        <tr>
                          <td style="width: 10%" ></td>
                          <td style="width: 80%" class="bordered"><input id="gefunden.bic" type="text" /></td>
                          <td style="width: 10%" ></td>
                        </tr>
                        </tbody>
                      </table><br>
        </td>
      </tr>
      </tbody>
    </table>
    <br>
    <table cellpadding="5" cellspacing="0" style="width: 100%" border="0"><tr>
      <td width="50%" style="text-align: right;"><div *ngIf="gefunden.fid!=0">
        <button mat-raised-button color="primary" size="large" (click)="save()"><div style="font-size: 2em">Daten übertragen</div></button>
      </div></td><td width="50%" style="text-align: left;">
      <div *ngIf="pdf!=0">
        &nbsp;<button mat-raised-button color="accent" size="large"><a href="./api/pdf.php?pid={{pdf}}" style="text-decoration:none" target="blank">
        <div style="font-size: 2em;color: white">PDF erstellen</div></a></button>
      </div></td></tr></table></div></div></div><br>








